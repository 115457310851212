<template>
  <div>
    <!--  -->
    <vs-table
      v-if="unfilteredApps.length > 0 || (applications.length > 0 && jobs.length > 0)"
      ref="table"
      pagination
      :max-items="itemsPerPage"
      :data="applications"
      class="w-full min-w-full"
      :hover-flat="true"
      @selected="handleSelected"
    >
      <div slot="header" class="items-center flex-grow mb-8">
        <div class="vx-row">
          <!-- ACTION - DROPDOWN -->

          <div class="vx-col lg:w-1/2 w-full mb-5 lg:mb-0 relative">
            <label class="typo__label">My jobs</label>
            <multiselect
              v-model="selectedJob"
              track-by="jobTitle"
              select-label=""
              label="jobTitle"
              placeholder="Job titles"
              open-direction="below"
              :show-labels="false"
              :options="jobs"
              :searchable="true"
              :allow-empty="true"
              @input="filtered"
            ></multiselect>
            <!-- <pre class="language-json"><code>{{ selectedJob  }}</code></pre> -->
          </div>

          <div class="vx-col lg:w-1/2 w-full relative">
            <label class="typo__label">Status</label>
            <multiselect
              v-model="selectedStages"
              select-label=""
              placeholder="Status"
              open-direction="below"
              track-by="name"
              :show-labels="false"
              label="name"
              :options="status"
              :searchable="true"
              :allow-empty="true"
              @input="filtered"
            ></multiselect>
          </div>
        </div>
      </div>
      <vs-divider></vs-divider>

      <template v-if="applications.length > 0 && jobs.length > 0" slot-scope="{ data }">
        <tbody class="bg-transparent shadow-none">
          <vs-tr v-for="(tr, indextr) in data" :id="tr.userId" :key="indextr" :data="tr" class="bg-transparent shadow-none">
            <!-- :class="data[indextr].userId === selected.userId ? 'is-selected' : ''" -->
            <div v-if="getProfileStatus(data[indextr].userId) === true" class="cursor-pointer">
              <ApplicationUserCard :application="data[indextr]" @refresh="filtered()"></ApplicationUserCard>
            </div>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <!-- No job posts -->
    <div
      v-if="unfilteredApps.length === 0 && jobs.length === 0 && applications.length === 0"
      class="px-24 py-32 rounded-lg bg-white h-10 border-solid border-2 border-grey-light min-w-full flex-col flex items-center justify-center"
    >
      <div>
        <p class="text-center font-bold text-lg"> No Active Jobs </p>
        <p class="mt-1 text-center text-grey"> Please visit the Jobs tab to create your first job post</p>
      </div>
      <div class="mt-4">
        <vs-button class="px-6 w-full" @click.native="toJobs">Post a job</vs-button>
      </div>
    </div>
    <!-- No applications -->
    <div
      v-if="unfilteredApps.length === 0 && applications.length === 0 && jobs.length > 0"
      class="px-24 py-32 rounded-lg bg-white h-10 border-solid border-2 border-grey-light min-w-full flex-col flex items-center justify-center"
    >
      <div>
        <p class="text-center font-bold text-lg"> No Job Applications </p>
        <p class="mt-1 text-center text-grey"> Please visit the Guide Board to invite guides to your job posts</p>
      </div>
      <div class="mt-4">
        <vs-button class="px-6 w-full" @click.native="toGuideBoard">View Guide Board</vs-button>
      </div>
    </div>

    <portal to="rag-sidebar">
      <chat-side
        v-if="isChatSidebarActive"
        :click-not-close="false"
        :active-chat-user="activeChatUser"
        :is-chat-pinned="isChatPinned"
        :user-data="userData"
        :selected-contact="selectedContact"
        :typed-message="typedMessage"
        :settings="settingsTwo"
        @close="closeChat"
        @update-active-chat-user="updateActiveChatUser"
      ></chat-side>
      <div v-else>
        <profile-user-profile ref="userProfile" :user="selectedUser"></profile-user-profile>
        <portal to="rag-sidebar-footer">
          <div id="appProfileActionBtns">
            <ApplicationButtons v-if="activeApp" />
          </div>
        </portal>
      </div>
    </portal>

    <portal to="chatButton">
      <vs-button
        id="appProfileMsgBtn"
        class="w-full sm:w-auto"
        icon="mail_outline"
        icon-before
        @click="isChatSidebarActive = !isChatSidebarActive"
        >Message
      </vs-button>
    </portal>
    <ApplicationsTour />
  </div>
</template>

<script>
import ApplicationUserCard from '../components/application-components/ApplicationUserCard';
import Multiselect from 'vue-multiselect';
import ChatSide from '../apps/chat/ChatSide';
import ApplicationsTour from '../components/tours/ApplicationsTour.vue';
import ApplicationButtons from '../components/application-components/ApplicationButtons.vue';
import ProfileUserProfile from '../components/guide-profile-components/ProfileUserProfile.vue';
export default {
  components: {
    ApplicationUserCard,
    Multiselect,
    ChatSide,
    ApplicationsTour,
    ApplicationButtons,
    ProfileUserProfile,
  },
  data() {
    return {
      active: true,
      isHidden: false,
      chatData: '',
      search: '',
      searchContact: '',
      activeProfileSidebar: false,
      activeChatUser: null,
      typedMessage: '',
      isChatPinned: false,
      userIds: [],
      clickNotClose: true,
      isChatSidebarActive: false,
      windowWidth: window.innerWidth,
      userData: {},
      selectedContact: null,
      applications: [],
      unfilteredApps: [],
      selectedUser: {},
      selectedApp: null,
      select: false,
      jobs: [],
      query: '',
      status: [
        { name: 'Applied', value: 0 },
        { name: 'Shortlisted', value: 2 },
        { name: 'Invited', value: 1 },
        { name: 'Declined', value: 3 },
      ],
      selectedJob: null,
      selectedStages: null,
      selectedLocation: '',
      itemsPerPage: 4,
      settings: {
        maxScrollbarLength: 200,
        wheelSpeed: 0.7,
      },
      settingsTwo: {
        maxScrollbarLength: 100,
        wheelSpeed: 0.7,
      },
      slide: false,
    };
  },
  computed: {
    activeApp() {
      return this.$store.state.activeApp;
    },
  },
  destroyed: function () {
    document.removeEventListener('keyup', this.myMethod);
  },
  created() {
    this.fetchData();
    document.addEventListener('keyup', this.myMethod);
    this.$nextTick(() => {
      if (this.$route.params.userToView) {
        document.getElementById(this.$route.params.userToView).scrollIntoView();
        document.getElementById(this.$route.params.userToView).click();
      }
    });
  },
  methods: {
    updateActiveChatUser(contactId) {
      this.activeChatUser = contactId.applicationsId;
      this.selectedContact = contactId;
      let chatData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      this.userData = chatData;
      this.isChatActive = true;
      if (chatData && chatData.userCount > 0) {
        chatData.userCount = 0;
        this.$store.dispatch('chatModule/set', chatData);
      }
      if (chatData) this.isChatPinned = chatData.isPinned;
      else this.isChatPinned = true;
      this.typedMessage = '';
    },
    getProfileStatus(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user) {
        if (!user.profileDeactivated) {
          if (user.active === true) {
            return true;
          } else {
            return false;
          }
        }
        if (user.profileDeactivated) {
          if (user.profileDeactivated === true || user.active === false) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    toJobs() {
      this.$router.push('/pages/jobs');
    },
    toGuideBoard() {
      this.$router.push('/pages/resume');
    },
    filtered() {
      this.fetchData();
      this.unfilteredApps = this.applications;
      this.applications = this.applications.filter(this.filteredStatus);
    },
    fetchData() {
      this.applications = [];
      this.formatData(this.$store.state.applicationsModule.data).forEach((app) => {
        this.applications.push(app);
      });

      this.applications.sort((x, y) => {
        let timeX = x.createdAt;
        let timeY = y.createdAt;
        return timeY - timeX;
      });
      this.jobs = this.formatData(this.$store.state.jobsModule.data);
    },
    removed() {
      this.applications = this.$store.state.applicationsModule.data;
    },
    filteredStatus(item) {
      if (this.selectedJob && this.selectedStages) {
        return item.jobId === this.selectedJob.jobId && item.status === this.selectedStages.value;
      } else if (this.selectedJob) {
        return item.jobId === this.selectedJob.jobId;
      } else if (this.selectedStages) {
        return item.status === this.selectedStages.value;
      } else {
        return true;
      }
    },

    myMethod(event) {
      if (event.keyCode === 27) {
        this.$store.dispatch('toggleSidebar', false);
      }
    },
    closeChat() {
      this.isChatSidebarActive = false;
    },
    async handleSelected(tr) {
      this.isChatSidebarActive = false;
      this.selectedApp = tr;
      this.$store.dispatch('setActiveApp', this.selectedApp);
      let user = this.$store.getters['userModule/getUser'][tr.userId];
      if (user) {
        if (!this.selectedApp.read) {
          this.selectedApp.read = true;
          this.$store.dispatch('applicationsModule/patch', this.selectedApp);
        }
        // this.filtered();
        this.selectedUser = user;
        this.$store.dispatch('toggleSidebar', true);
        this.activeChatUser = tr.applicationsId;
        this.isChatPinned = true;
        this.selectedContact = tr;
        this.typedMessage = '';
        this.userData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      } else {
        this.$vs.notify({
          color: 'danger',
          title: 'User does not exist',
        });
        return;
      }
    },
    formatData(data) {
      let formattedData = Object.values(data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = fields[key];
        }

        return obj;
      });

      return formattedData;
    },
  },
};
</script>
<style lang="scss">
// .vs-sidebar--footer {
//   border: none !important;
// }
@media (min-width: 576px) {
  .applications .vs-sidebar--background {
    z-index: 52000 !important;
  }
}

.pointer {
  cursor: pointer;
}
h1 {
  color: #adca63;
  background: rgb(53, 53, 53);
}
.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  padding-bottom: 0px !important;
}
.vs-table--tbody {
  z-index: 0 !important;
}

@media (max-width: 768px) {
  .vs-table--tbody-table {
    min-width: 100px !important;
  }
}

.vs-avatar--con-img img {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
}
.chat-bar {
  max-width: 700px !important;
  top: 40px !important;
}
.chat-bar .vs-sidebar {
  max-width: 700px;
}

.force-overflow {
  min-height: 450px;
}

// .small {
//   height: calc(100vh - 130px);
// }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
